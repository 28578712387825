<template>
  <div>
    <section class="page-title text-center">
      <div class="auto-container">
        <div class="content-box">
          <h1>{{ item.name }}</h1>
          <div class="text">
            <nav class="woocommerce-breadcrumb">
              <router-link to="/">الرئيسيه</router-link
              >&nbsp;/&nbsp;<router-link
                :to="`/category/${item.categories.slug}`"
                >{{ item.categories.name }}</router-link
              >&nbsp;/&nbsp;{{ item.name }}
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="page-details ">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="product-details-content">
              <h2>{{ item.name }}</h2>
              <div class="product_meta">
                <p class="posted_in">
                  القسم:
                  <router-link :to="`/category/${item.categories.slug}`">{{
                    item.categories.name
                  }}</router-link>
                </p>
              </div>
              <p v-html="item.body"></p>

              <p class="price" v-if="item.newPrice > 0">
                {{ item.newPrice }} جنيه مصرى
                <del>{{ item.price }} جنيه مصرى</del>
              </p>
              <p class="price" v-else>{{ item.price }} جنيه مصرى</p>
              <div class="cart-btn">
                <a v-b-modal.modal-1 class="pay"
                  ><i class="fa fa-cart-plus"></i>طلب
                </a>
                <b-modal
                  id="modal-1"
                  size="lg"
                  centered
                  cancel-title="اغلاق"
                  ok-title="طلب المنتج"
                  title="طلب المنتج"
                  @ok="handleOk"
                >
                  <div class="form-group">
                    <label for="Products-location">الاسم الكامل</label>

                    <input
                      type="text"
                      name="name"
                      v-model="body.name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="Products-location">الهاتف</label>

                    <input
                      type="text"
                      name="phone"
                      v-model="body.phone"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <label for="Products-location">طريقه الدفع</label>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="baytype"
                        v-model="body.baytype"
                        id="baytype1"
                        :value="false"
                        checked
                      />
                      <label class="form-check-label" for="baytype1">
                        كاش
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="baytype"
                        id="baytype2"
                        v-model="body.baytype"
                        :value="true"
                      />
                      <label class="form-check-label" for="baytype2">
                        اون لاين
                      </label>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="Products-status">ملاحظات</label>

                    <textarea
                      name="note"
                      v-model="body.note"
                      rows="3"
                      class="form-control"
                    ></textarea>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="4000"
              controls
              indicators
              class="carousel-slide-b"
              img-width="1024"
              img-height="480"
              fade
            >
              <b-carousel-slide
                key="0"
                :img-src="baseUploadURL + item.logo"
              ></b-carousel-slide>
              <b-carousel-slide
                v-for="itm of list"
                :key="itm.id"
                :img-src="baseUploadURL + itm.image"
              ></b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUploadURL: this.$baseUploadURL,
      item: {
        id: null,
        name: null,
        logo: null,
        image: [],
        body: null,
        price: 1,
        newPrice: 0,
        created_at: null,
        categories_id: null,
        categories: {
          name: null,
          slug: null,
        },
      },
      body: {
        name: null,
        phone: null,
        note: null,
        baytype: false,
        products_id: null,
      },
    };
  },
  methods: {
    handleOk() {
      if (this.body.name && this.body.phone) {
        this.body.products_id = this.item.id;
        this.$http.post(`order/add`, this.body).then(
          (res) => {
            this.$swal.fire({
              icon: 'success',
              title: 'Success',
              text: `تم طلب المنتج بنجاح وسيتم تحويلك على صفحه الدفع`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (this.body.baytype) {
              setTimeout(() => {
                window.location.replace(res.data);
              }, 1500);
            } else {
              this.body.name = null;
              this.body.phone = null;
              this.body.note = null;
              this.body.baytype = false;
            }
          },
          () => {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: `حدث خطأ فى الاتصال`,
              showConfirmButton: false,
              timer: 1500,
            });
          },
        );
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: `يرجى ملاء الحقول`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    if (id) {
      this.$http.get(`products/getById/${id}`).then(
        (res) => {
          if (!res.data.error) {
            this.item = res.data;
          }
        },
        () => {
          this.$bvToast.toast(`حدث خطأ فى الاتصال`, {
            autoHideDelay: 1500,
            variant: 'danger',
            appendToast: 'true',
            toaster: 'b-toaster-bottom-right',
          });
        },
      );
    }
  },
  watch:{
    '$route.params.id'(id){
      if (id) {
      this.$http.get(`products/getById/${id}`).then(
        (res) => {
          if (!res.data.error) {
            this.item = res.data;
          }
        },
        () => {
          this.$bvToast.toast(`حدث خطأ فى الاتصال`, {
            autoHideDelay: 1500,
            variant: 'danger',
            appendToast: 'true',
            toaster: 'b-toaster-bottom-right',
          });
        },
      );
    }
    }
  }
};
</script>
<style>
.product-details-content img{
 width: 100%;
 height: auto;
}

</style>
